.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Authentification */

[data-amplify-authenticator] {
  max-width: 100%;
}

[data-amplify-authenticator][data-variation=modal] {
  background-color: #fff
}

/* Sortable tree */
.rst__rowWrapper {
  padding: 0px;
}

.rst__rowContents {
  cursor: pointer;
}

.non-selectable-node .rst__rowContents {
  cursor: default;
  background-color: #F5F5F5;
}

.no-data-node .rst__rowContents {
  background-color: #F5F5F5;
}

.selectable-node .rst__rowContents {
  background-color: #ffffff;
}

.disabled-node .rst__rowContents {
  cursor: default;
  background-color: #fbfbfb;
}